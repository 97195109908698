import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import User from 'components/data/User';
import Templates from 'components/data/Templates';
import ComponentStore from 'components/data/ComponentStore';
import { isTemplateDesignerTemplateType } from 'components/template-management/utilities';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import store from '../../../store';

/**
 * EditorData
 * Class is used for getting values from the editor data set.
 * This includes various functions to add data, remove and update data.
 * Also there are a few shorthands for getting data.
 */
export default class EditorData {
    /**
     * Validate the condition that is passed
     * @param {*} item The full data object of the item
     * @param {*} blockData If available the  block data
     * @param {*} blockDataParent The parent block data of the item
     */
    static validateCondition(item, blockData = {}, blockDataParent = {}, additionalVars = {}) {
        const editor = store.getState().editor;
        const language = store.getState().editor.language;
        let auth = { ...store.getState().auth, name: '', email: '', token: '' }; // eslint-disable-line
        let campaign = editor.data; // eslint-disable-line
        let data = editor.data; // eslint-disable-line

        // Validate condition and check security
        if (item.condition) {
            const toCheck = ['axios', 'fetch', 'function', 'alert', 'cookie', '__proto', '()=', 'token', 'getState', 'User.'];
            for (const i in toCheck) {
                if (item.condition.includes && item.condition.includes(toCheck[i])) {
                    return;
                }
            }
        }

        // Sometimes the additonalVars are within the item object instead of as a seperate value
        if (item.additionalVars) {
            additionalVars = item.additionalVars; // eslint-disable-line
        }

        // If we are in a TD template there can be default value set in the template. We need the default value to show / hide inputs.
        let templateDefaultData = EditorData.getTemplateDesignerDefaultData(item.blockModel);
        if (templateDefaultData) {
            const selectedFormat = ComponentStore.getItem('CreativeEditor', 'selectedFormats')?.[0] || 'general';
            // We merge the format specific default data over the general default data to get the full default data
            templateDefaultData = merge(cloneDeep(templateDefaultData['general']), templateDefaultData[selectedFormat]);
            // We merge the default value with the block data and check for empty objects. Because some inputs return an empty object if the value was deleted.
            // If the value was deleted we dont want to revert to the default value. We want the input to stay empty.
            // Because we merge the default value with the block data the condition does the rest of the work and decides if the input should be shown or not.
            blockData = mergeWith({}, templateDefaultData, blockData, (objValue, srcValue) => {
                const isObject = (value) => {
                    return value !== null && typeof value === 'object' && !Array.isArray(value);
                };

                const isEmptyObject = (obj) => {
                    return isObject(obj) && Object.keys(obj).length === 0;
                };

                if (isObject(objValue) && isObject(srcValue)) {
                    // If the source value is an empty object, overwrite the destination value
                    if (isEmptyObject(srcValue)) {
                        return srcValue;
                    }
                }
                // Use default merging for all other cases
                return undefined;
            });
        }
        // Also accept conditions that start with 'block' instead of 'blockData'
        let block = blockData; // eslint-disable-line

        let conditionResultTemplate = false;
        // Check if condition is set and evaluate
        if (item.condition) {
            let conditionResult = false;
            try {
                conditionResult = eval(item.condition); // eslint-disable-line
            } catch (e) {}

            // The templateCondition is currently depricated. But still in use on older templates.
            // Condition not validated, also look into template condition
            if (!conditionResult) {
                if (item.templateCondition) {
                    try {
                        conditionResultTemplate = eval(item.templateCondition); // eslint-disable-line
                    } catch (e) {}

                    if (!conditionResultTemplate) return false;
                } else {
                    return false;
                }
            }
        }

        // Check whether we want to check for the user right
        if (item.userHasRight) {
            if (!User.hasRight(item.userHasRight)) {
                return false;
            }
        }

        // Show if has copy
        if (item.showIfTextIsSet) {
            let conditionResult = false;
            try {
                let data = eval(item.condition); // eslint-disable-line
                if (data && data.value && data.value !== '') {
                    conditionResult = true;
                }
                if (data && data.multilanguage && data[language] && data[language].value && data[language].value !== '') {
                    conditionResult = true;
                }
            } catch (e) {
                // if object is not set (undefined error) we check if the parent has a default value and set conditionResult to true;
                if (conditionResultTemplate) conditionResult = true;
            }

            if (!conditionResult && !conditionResultTemplate) return false;
        }

        // Check wether we want to check for the user level
        if (item.userHasLevel) {
            if (!User.hasLevel(item.userHasLevel)) {
                return false;
            }
        }
        return true;
    }

    /***
     * Gets the current active id
     * @returns {int} ID
     */
    static getId() {
        return store.getState().editor.id;
    }

    /***
     * Gets an element from the editor object
     * @returns {value} ID
     */
    static get(field) {
        if (field) {
            return store.getState().editor[field];
        } else {
            return store.getState().editor;
        }
    }

    /***
     * Gets the current active language
     * @returns {string} Language code
     */
    static getLanguage() {
        return store.getState().editor.language;
    }

    /***
     * Gets the current active language
     * @returns {string} Language code
     */
    static setLanguage(language) {
        store.dispatch({
            type: 'EDITOR_CHANGE_LANGUAGE',
            payload: {
                language: language
            }
        });
    }

    /**
     * Get the value from a local model
     * @param {*} model The model location path. E.g. settings.title
     * @param {*} baseObject The baseobject to use. This is used when you don't want to use the data from the object, but from the local object.
     * @param {*} localScope In case we have a local scope. The local scope is used for isolated scope.
     */
    static getValueFromModel(model, baseObject = undefined, localScope = undefined, replaceItemsAdditional = {}) {
        // We have an isolated local scope. This fetches the local scope and sets that as the base object
        if (localScope) {
            const baseObject = store.getState().editor.localScope[localScope];
            return EditorData.getDynamicValue('[[[baseObject]]].' + model, {
                baseObject: baseObject ? baseObject : {},
                ...replaceItemsAdditional
            });
        }
        // We have a base object. Use this object.
        else if (baseObject) {
            return EditorData.getDynamicValue('[[[baseObject]]].' + model, { baseObject: baseObject, ...replaceItemsAdditional });
        }
        // This is the gloval editor data
        else {
            // Uses the dynamic value, but prepends [[campaign]]
            return EditorData.getDynamicValue('[[[baseObject]]].' + model, replaceItemsAdditional);
        }
    }

    /**
     * Get a dynamic value from the redux store
     * @param {*} command
     * @param {*} replaceItemsAdditional In case you want to pass additional parameters (optional). E.g. when you want to say [[[testData]]] in the data model, and pass the object testData as an additional detial.
     */
    static getDynamicValue(command, replaceItemsAdditional = {}) {
        // Setup initial variables that are used for evaluating
        let replaceItems = [];
        replaceItems.campaign = store.getState().editor.data;
        replaceItems.data = replaceItems.campaign;
        replaceItems.editor = store.getState().editor;
        replaceItems.language = store.getState().editor.language;
        replaceItems.market = store.getState().editor.market;
        replaceItems.brand = store.getState().editor.brand;
        replaceItems.origin = store.getState().editor.origin;
        replaceItems.flight = store.getState().editor.flight;
        replaceItems.setup = store.getState().resources.setup;
        replaceItems.auth = { ...store.getState().auth, name: '', email: '', token: '' };
        replaceItems = { ...replaceItems, ...replaceItemsAdditional };

        // In case we do not have a base object, the
        if (!replaceItems.baseObject) {
            replaceItems.baseObject = replaceItems.campaign;
        }

        // Also make them available as a standalone object reference
        const editor = replaceItems.editor; // eslint-disable-line
        const language = replaceItems.language; // eslint-disable-line
        const market = replaceItems.market; // eslint-disable-line
        const campaign = replaceItems.campaign; // eslint-disable-line
        const data = replaceItems.data; // eslint-disable-line
        const setup = replaceItems.setup; // eslint-disable-line
        const auth = replaceItems.auth; // eslint-disable-line
        const blockData = replaceItems.blockData; // eslint-disable-line
        const baseObject = replaceItems.baseObject; // eslint-disable-line
        const additionalVars = replaceItems; // eslint-disable-line
        const vars = replaceItems; // eslint-disable-line

        // Old version fix
        if (!String.prototype.replaceAll) {
            String.prototype.replaceAll = function (search, replace) {
                // eslint-disable-line
                return this.split(search).join(replace);
            };
        }

        // In case we have a blockmodel, we translate it into the final full path
        if (replaceItems.blockModel && command && command.replaceAll) {
            command = command.replaceAll('[[blockModel]]', '[[[baseObject]]].' + replaceItems.blockModel);
        }

        // Evaluate using objects
        let doEval = false;
        if (command.includes('[[[')) {
            doEval = true;
        }
        // Evaluate using full JS
        if (command.includes('{{{')) {
            command = command.replace('{{{', '');
            command = command.replace('}}}', '');
            doEval = true;
        } else if (command.includes('$[')) {
            command = command.replace('$[', '');
            command = command.replace(']$', '');
            doEval = true;
        }

        // Validate condition and check security
        if (command) {
            const toCheck = ['axios', 'fetch', 'function', 'alert', 'cookie', '__proto', '()=', 'token', 'getState', 'User.'];
            for (const i in toCheck) {
                if (command.includes(toCheck[i])) {
                    doEval = false;
                }
            }
        }

        // Loop through all replace Items to replace the data details
        Object.keys(replaceItems).forEach((key) => {
            // We have an object that is referred to. E.g. [[[campaign]]].settings
            command = command
                .replaceAll('[[[' + key + ']]]', 'replaceItems.' + key)
                .replace('[[[' + key + ']]]', 'replaceItems.' + key)
                .replace('[[[' + key + ']]]', 'replaceItems.' + key);
            // We want to replace a value in the model. E.g. [[language]]
            command = command
                .replaceAll('[[' + key + ']]', replaceItems[key])
                .replace('[[' + key + ']]', replaceItems[key])
                .replace('[[' + key + ']]', replaceItems[key]);
        });

        // Try the eval, and if failed, return undefined
        try {
            if (doEval) {
                let val = eval(command); // eslint-disable-line
                return val;
            } else {
                return command;
            }
        } catch (e) {}
        return undefined;
    }

    /**
     * Remove item from the current editor store
     * @param {*} path
     */
    static removeItem(path) {
        // Remove item from store
        EditorData.getDynamicValue('{{{delete campaign.' + path + '}}}');
    }

    /**
     * Parse data
     * This function takes an object and loops through all subobjects.
     * For every string that is found, we check whether we need to parse the value for one-way binding. E.g. {{{eval}}}
     * @param {*} sourceData
     * @param {*} variables
     */
    static parseDataDeep = (sourceData, variables = {}, localScope = undefined) => {
        if (sourceData === null) {
            return;
        }

        Object.keys(sourceData).forEach((key) => {
            // Reserved keywords
            if (key === 'model' || key === 'blockModel' || key === 'sourceDataModel' || key === 'settingsInterfaceSetup') {
                return;
            }
            // Item is an object, get dynamic data
            else if (typeof sourceData[key] === 'object') {
                EditorData.parseDataDeep(sourceData[key], variables, localScope);
            }
            // Item is a string, get dynamic data
            else if (typeof sourceData[key] === 'string') {
                if (sourceData[key].includes('[[') || sourceData[key].includes('[[[') || sourceData[key].includes('{{{') || sourceData[key].includes('$[')) {
                    if (localScope) {
                        sourceData[key] = EditorData.getValueFromModel(sourceData[key], undefined, localScope, variables);
                    } else {
                        sourceData[key] = EditorData.getDynamicValue(sourceData[key], variables);
                    }
                }
            }
        });
    };

    /**
     * Set value of a model location in the campaign store
     * @param {*} model The model path, e.g. settings.copy.field1
     * @param {*} value The new value
     * @param {*} linkedBlockModels Linked block models, that also need to be updated together with the current model
     * @param {*} localScope The local scope. This is a unique string that will
     */
    static setModel(model, value, linkedBlockModels = [], localScope = undefined) {
        const toDispatch = [model, ...linkedBlockModels];
        toDispatch.forEach((x) => {
            store.dispatch({
                type: 'EDITOR_CHANGE_MODEL',
                payload: {
                    model: x,
                    value: value,
                    localScope: localScope
                }
            });
        });
    }

    /**
     * Change state of the editor
     * @param {*} model The model to write to
     * @param {*} value The new value
     */
    static setEditorState(model, value) {
        store.dispatch({
            type: 'EDITOR_CHANGE_STATE',
            payload: {
                [model]: value
            }
        });
    }

    /***
     * Reset the editor
     */
    static reset() {
        store.dispatch({
            type: 'EDITOR_RESET',
            payload: {}
        });
    }

    /**
     * Gets the default value of the the template that is used in the creative builder.
     * Only if the template is a template designer template.
     * @param {*} blockModel Current blockModel
     * @returns template default data
     */
    static getTemplateDesignerDefaultData = (blockModel) => {
        if (!blockModel) return;

        // Get the template default data from the CreativeEditorV2 component
        const creativeEditorV2 = ComponentStore.get('CreativeEditorV2');
        const creativeV2Data = creativeEditorV2?.creative?.data;

        // This will only work for the CreativeEditorV2
        if (creativeV2Data && creativeV2Data.templateIdentifier && typeof creativeV2Data.templateIdentifier === 'string') {
            const template = TemplateManager.getTemplateByIdentifier(creativeV2Data.templateIdentifier);
            return cloneDeep(template?.data?.templateSetup?.templateDefaultData);
        }

        // Get required items from the ComponentStore
        const creativeBuilderItemUuid = ComponentStore.getItem('CreativeEditor', 'uuid') || '';
        const frameNr = ComponentStore.getItem('CreativeEditor', 'frameNr') || '1';
        const editorData = EditorData.get('data');

        // Extract the creativeBuilderModel and creativeBuilder
        const creativeBuilderModel = blockModel.split(`.${creativeBuilderItemUuid}`)[0];
        const creativeBuilder = get(editorData, creativeBuilderModel);

        if (!creativeBuilder) return;

        const creativeBuilderItem = creativeBuilder[creativeBuilderItemUuid];
        if (!creativeBuilderItem) return;

        // Determine the template type.
        const templateType =
            creativeBuilderItem.type === 'socialChannelItem' && creativeBuilderItem.assetSetup?.frames !== undefined
                ? get(creativeBuilderItem, `assetSetup.frames.frame${frameNr}.type`)
                : creativeBuilderItem?.assetSetup?.type;

        if (!isTemplateDesignerTemplateType(templateType)) return;

        // Determine the template identifier.
        const templateIdentifier =
            creativeBuilderItem.type === 'socialChannelItem' && creativeBuilderItem.assetSetup?.frames !== undefined
                ? get(creativeBuilderItem, `assetSetup.frames.frame${frameNr}.templateIdentifier`)
                : creativeBuilderItem?.assetSetup?.templateIdentifier;

        if (!templateType || !templateIdentifier) return;

        // Return the template default data
        return cloneDeep(Templates.get(templateType, templateIdentifier)?.templateSetup?.templateDefaultData);
    };
}
